import React from 'react';

import { borderRadius } from 'react-select/src/theme';
import useFilterHead from './useFilterHeader';

interface IProps {
  title: string;
  value: string;
  responsiveMode: boolean;
  isFlightPage?: boolean;
}

const FilterHeader: React.FC<IProps> = ({
  children,
  title,
  value,
  responsiveMode,
  isFlightPage,
}) => {
  const { handleClick, titleSelected, isOpen } = useFilterHead(title);

  return (
    <>
      <div
        onClick={() => handleClick(title)}
        style={{borderRadius:"0px 30px 0px 30px",marginRight: "10px",marginBottom:"10px"}}
        className={`d-flex flex-column w-100 bg-white ${
          title === 'Durée' || title === 'Date de vol' ? 'border-right-0' : 'border-right'
        } ${
          responsiveMode
            ? 'border-right-0'
            : ` ${titleSelected === title ? 'active-btn-style border-right-0' : ''} ${
                titleSelected === 'Durée' || titleSelected === 'Date de vol'
                  ? 'active-btn-style-border-radius'
                  : ''
              } `
        } btn container d-flex flex-row ${
          responsiveMode
            ? `justify-content-between ${
                titleSelected === title ? 'active-btn-style' : 'border-bottom'
              }`
            : 'justify-content-center my-auto'
        }`}
      >
        <div
          className={`my-auto ${titleSelected === title ? 'text-white' : ''}  ${
            responsiveMode
              ? 'd-flex flex-row justify-content-between header-properties-responsive'
              : 'd-flex flex-row justify-content-between'
          } `}
        >
          <div
            className={`${
              responsiveMode
                ? 'd-flex flex-row justify-content-between header-properties-responsive '
                : 'header-properties align-self-center'
            }`}
          >
            <div
              className={`d-flex justify-content-start ${
                titleSelected === title ? 'text-white' : ''
              } ${responsiveMode ? 'col-6' : ''}`}
            >
              <p className={`m-0 h6 ${responsiveMode ? 'mr-auto align-self-center' : ''}`}>
                {title}
              </p>
            </div>
            <div className={`d-flex flex-row ${responsiveMode ? 'col-6' : ''}`}>
              <p className={`m-0 h5 value-style ${responsiveMode ? 'align-self-center' : ''}`}>
                {value}
              </p>
              <i
                className={` ${
                  value.includes('importe') || value.includes('Tous')
                    ? ''
                    : 'fas fa-check text-success'
                }  my-auto ml-1 `}
              />
            </div>
          </div>
          <button type="button" className="btn px-1">
            <i
              className={`fas  ${
                isOpen ? 'fa-chevron-up' : 'fa-chevron-down'
              } d-flex align-self-center ${titleSelected === title ? 'text-white' : ''} `}
            />
          </button>
        </div>
      </div>
      <div
        className={`container ${
          responsiveMode
            ? ''
            : `position-absolute ${
                value === 'VOLS' ? 'flight-option-container' : 'option-container'
              } `
        } `}
      >
        {isOpen && (
          <div
            style={{ zIndex: 1, background: '#fff' }}
            className={`container ${
              responsiveMode
                ? 'filter-container-responsive'
                : `${isFlightPage && 'w-75'} shadow-serious ${
                    value === 'VOLS' ? 'flight-filter-container' : 'filter-container'
                  } `
            } border  `}
          >
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default FilterHeader;
